<template>
  <div>
      <el-dialog
          :close-on-click-modal="false"
          :visible.sync="refuseDialogFlag"
          append-to-body :show-close="false"
          center
          :title="$t('RefusePost')">
        <el-form :model="refuseDialogParam">
          <el-form-item>
            <div class="item-box">
              <!--产品类型-->
              <div class="top-product-type-box" v-if="refuseDialogParam.productTypes && refuseDialogParam.productTypes.length!==0 && refuseDialogParam.postType !== 3">
                <span># {{threeProduct(refuseDialogParam.productTypes)}}</span>   <!--仅显示前3条，多余的用省略号显示-->
              </div>
      
              <!-- 顶部区域 -->
              <div class="top-box">
                <!--logo-->
                <div class="top-left-box">
                  <div class="img-box">
                    <img :src="refuseDialogParam.chamberAvatar" alt="商会头像"/>
                  </div>
                  <p>{{ refuseDialogParam.chamberName }}成员</p>
                </div>
        
                <!--标题-->
                <div class="top-right-box">
                  <div class="info-box">
                    <!--                    <div class="title-box">
                                          <b>【{{item.productName}}】</b> We are a listed private enterprise coal power generation company. The cold winter is
                                          approaching and the demand for electricity will increase sharply. We hope to purchase 10 tons of
                                          coal at one time and seek long-term coal suppliers
                                        </div>-->
                    <div class="title-box">
                      <b v-if="refuseDialogParam.productName">【{{refuseDialogParam.productName}}】</b> {{refuseDialogParam.productTitle||refuseDialogParam.partnerTitle}}
                    </div>
                    <div class="accept-price-box" v-if="refuseDialogParam.acceptPrice">
                      <span v-if="refuseDialogParam.postType === 0">{{ $t('TendPrice') }}: {{refuseDialogParam.priceType +'&nbsp'+refuseDialogParam.acceptPrice+'/'+refuseDialogParam.unit}}</span>
                      <span v-else>{{ $t('SellPrice') }}: {{refuseDialogParam.priceType +'&nbsp'+refuseDialogParam.acceptPrice+'/'+refuseDialogParam.unit}}</span>
                    </div>
                    <div v-else>
                      <ul class="info-detail" style="list-style: none">
                        <li class="ellipsis">{{ $t('ProjectIntro') }}：{{ refuseDialogParam.partnerDescription }}</li>
                        <li v-if="refuseDialogParam.cooperateWay">{{ $t('CooperationMethod') }}：{{ refuseDialogParam.cooperateWay }}</li>
                        <li v-if="refuseDialogParam.partnerRequest">{{ $t('CoopRequest') }}：{{ refuseDialogParam.partnerRequest }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
      
              <!-- 底部区域 -->
              <ul class="bottom-box">
                <li>id: {{refuseDialogParam.postId||refuseDialogParam.partnerId}}</li>
                <li>{{$t('ValidTill')}}: {{refuseDialogParam.validDate}}</li>
                <li>{{refuseDialogParam.postingCountryId}}</li>
                <li>{{$t('PublishTime')}}: {{refuseDialogParam.createTime||refuseDialogParam.createDate}}</li>
              </ul>
            </div>
          </el-form-item>
          
<!--          拒绝理由-->
<!--          <el-form-item :label="$t('RefuseReason')">
            <el-input
              type="textarea"
              v-model="refuseReason"
              resize="none"
              :placeholder="$t('NonNecessary')"
              :autosize="{ minRows: 2, maxRows: 4}"
            ></el-input>
          </el-form-item>-->
          
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{this.$t('Cancle')}}</el-button>
          <el-button type="primary" @click="refusePost(refuseDialogParam.postId||refuseDialogParam.partnerId)">{{$t('RefusePost')}}</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/fetch/api'
import mixin from '@/assets/js/mixin'

export default {
  mixins: [mixin],
  name: 'RefuseDialog',
  components: {},
  props:{
    refuseDialogFlag: Boolean,
    refuseDialogParam: Object
  },
  data() {
    const data = JSON.parse(localStorage.getItem('cacheChamber')).adminData;
    return {
      adminId:data.id,
      refuseReason:'',
      chamberId: data.chamberId
    };
  },
  created() {},
  mounted() {
  },
  methods: {
    
  //   关闭弹窗
    closeDialog(){
      this.$emit('closeRefuse',false)
    },
    
  //   拒绝帖子
    refusePost(id){
      let param = {
        currentAdmin:this.adminId,
        postId:id,
        postType:this.refuseDialogParam.postType,     // 0买家/卖家   1合作伙伴
        action:1,      // 0通过   1拒绝
        refuseReason:this.refuseReason
      }
      /**
       * 若是从接收帖子拒绝， isReceive 应传 true，（ReceivePost.vue）
       * 若是从成员帖子来的， isReceive 传 false.（UserPost.vue）
       * **/
      api.getReviewPost(param).then(res=>{
        if(res.code === 200){
          this.$message.success(this.$t('Deleted'));
          this.closeDialog()
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 268px;
  background-color: #ebf0f6;
  margin-bottom: 15px;
  padding: 20px 20px 10px 20px;
  
  // 产品类型
  .top-product-type-box {
    color: #7b7b7b;
  }
  
  // 顶部区域
  .top-box {
    display: flex;
    
    .top-left-box {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px dashed #3a3b3d;
      
      .img-box {
        img {
          width: 120px;
          height: 120px;
        }
      }
      
      & > p {
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
      }
    }
    
    .top-right-box {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      
      .info-box {
        flex: 1;
        width: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 40px;
        
        & > .title-box {
          color: #3d3d3d;
          line-height: 20px;
        }
        
        & > .info-detail {
          color: #7b7b7b;
          font-size: 14px;
          
          li {
            margin-bottom: 10px;
          }
          
          li:last-child {
            margin-bottom: 0;
          }
        }
        
        & > .accept-price-box {
          color: #7b7b7b;
          font-size: 14px;
        }
      }
      
      .btn-box {
        .btn-item {
          width: 100px;
          line-height: 30px;
          text-align: center;
          margin-bottom: 10px;
          font-size: 14px;
          border: 1px solid #010101;
          cursor: pointer;
        }
        
        .btn-item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  
  // 底部区域
  .bottom-box {
    display: flex;
    color: #3d3d3d;
    font-size: 14px;
    flex-flow: row nowrap;
    list-style-type: none;
    justify-content: space-around;
    
    & > .flex1 {
      flex: 1;
    }
    
    & > .flex2 {
      flex: 2;
    }
    
    & > li:last-child {
      padding-right: 20px;
      text-align: right;
      
      span {
        margin-left: 5px;
      }
    }
  }
  
  // 三个点
  .three-point-box {
    position: absolute;
    top: 20px;
    right: 40px;
    cursor: pointer;
    
    & > span {
      display: inline-block;
      width: 3px;
      height: 3px;
      margin-right: 3px;
      border-radius: 50%;
      background-color: #000000;
    }
    
    & > span:last-child {
      margin-right: 0;
    }
    
    .el-icon-more {
      font-size: 20px;
    }
    
    .report-button {
      position: absolute;
      top: -20px;
      right: -10px;
      min-width: 80px;
      margin: 10px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      
      .el-button {
        background-color: #EAD78C;
        color: red;
      }
    }
    
  }
  
}
</style>